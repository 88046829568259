import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import ElementUI from 'element-ui';

import 'element-ui/lib/theme-chalk/index.css';




const locale = {
  el: {
    colorpicker: {
      confirm: 'OK',
      clear: 'Clear'
    },
    datepicker: {
      now: 'Now',
      today: 'Today',
      cancel: 'Cancel',
      clear: 'Clear',
      confirm: 'OK',
      selectDate: 'Select date',
      selectTime: 'Select time',
      startDate: 'Start Date',
      startTime: 'Start Time',
      endDate: 'End Date',
      endTime: 'End Time',
      prevYear: 'Previous Year',
      nextYear: 'Next Year',
      prevMonth: 'Previous Month',
      nextMonth: 'Next Month',
      year: '',
      month1: 'January',
      month2: 'February',
      month3: 'March',
      month4: 'April',
      month5: 'May',
      month6: 'June',
      month7: 'July',
      month8: 'August',
      month9: 'September',
      month10: 'October',
      month11: 'November',
      month12: 'December',
      week: 'week',
      weeks: {
        sun: 'Sun',
        mon: 'Mon',
        tue: 'Tue',
        wed: 'Wed',
        thu: 'Thu',
        fri: 'Fri',
        sat: 'Sat'
      },
      months: {
        jan: 'Jan',
        feb: 'Feb',
        mar: 'Mar',
        apr: 'Apr',
        may: 'May',
        jun: 'Jun',
        jul: 'Jul',
        aug: 'Aug',
        sep: 'Sep',
        oct: 'Oct',
        nov: 'Nov',
        dec: 'Dec'
      }
    },
    select: {
      loading: 'Loading',
      noMatch: 'No matching data',
      noData: 'No data',
      placeholder: 'Select'
    },
    cascader: {
      noMatch: 'No matching data',
      loading: 'Loading',
      placeholder: 'Select',
      noData: 'No data'
    },
    pagination: {
      goto: 'Go to',
      pagesize: '/page',
      total: 'Total {total}',
      pageClassifier: ''
    },
    messagebox: {
      title: 'Message',
      confirm: 'OK',
      cancel: 'Cancel',
      error: 'Illegal input'
    },
    upload: {
      deleteTip: 'press delete to remove',
      delete: 'Delete',
      preview: 'Preview',
      continue: 'Continue'
    },
    table: {
      emptyText: 'No Data',
      confirmFilter: 'Confirm',
      resetFilter: 'Reset',
      clearFilter: 'All',
      sumText: 'Sum'
    },
    tree: {
      emptyText: 'No Data'
    },
    transfer: {
      noMatch: 'No matching data',
      noData: 'No data',
      titles: ['List 1', 'List 2'], // to be translated
      filterPlaceholder: 'Enter keyword', // to be translated
      noCheckedFormat: '{total} items', // to be translated
      hasCheckedFormat: '{checked}/{total} checked' // to be translated
    },
    image: {
      error: 'FAILED'
    },
    pageHeader: {
      title: 'Back' // to be translated
    },
    popconfirm: {
      confirmButtonText: 'Yes',
      cancelButtonText: 'No'
    },
    empty: {
      description: 'No Data'
    }
  }
};
// axios.defaults.baseURL = "//localhost:3000/"  //localhost
axios.defaults.baseURL = "//music.original-fun.com/"



axios.defaults.withCredentials = true;
Vue.config.productionTip = false
Vue.use(ElementUI, { locale });

window.L18N = {
  "分享链接已复制到剪贴板": "Share link copied to clipboard",
  "请输入": "please enter",
  "登录": "login",
  "请输入邮箱": "Please enter email",
  "请输入密码": "Please enter password",
  "忘记密码": "I Forget My password",
  "还没有账号，去注册": "No account yet, go to register",
  "重置密码": "Reset password",
  "请输入原密码": "Please enter the old password",
  "请输入新密码": "Please enter a new password",
  "请重复输入新密码": "Please repeat the new password",
  "确认修改": "Confirm modification",
  "输入 6-20 位密码": "Enter a 6-20 digit password",
  "两次输入的密码不一致": "The two entered passwords are inconsistent",
  "注册": "Register",
  "请输入验证码": "Please enter the verification code",
  "发送验证码邮件": "Send verification code email",
  "提交注册": "Submit registration",
  "请输入正确的邮箱地址": "Please enter the correct email address",
  "更新头像成功": "Update avatar successfully",
  "更新昵称成功": "Update nickname successfully",
  "请输入昵称": "Please enter the nickname",
  "自我介绍": "Self-introduction",
  "音乐故事": "Music Story",
  "请输入自我介绍": "Please enter self-introduction",
  "关注": "Follow",
  "被关注": "Be Followed",
  "我的赞": "thumb ",
  "我诞生了": "I was born",
  "完成": "finish",
  "保存": "save",
  "发布到音乐厅": "Release to concert hall",
  "仅保存到我的创作": "Save only to my creation",
  "登录后发布到音乐厅": "Log in and post to concert hall",
  "分享到社交媒体": "Share it on social media",
  "彻底删除账号，并清空我的数据": "Delete my account",
  "您确定删除您的账号么?": "Are you sure to delete your account?",
  "再次确认,您确定删除您的账号么? 操作无法撤回": "Are you sure to delete your account? Operation cannot be withdrawn",
  "您的账号已删除": "Your account has been deleted",


}


window.L = (key: string) => {
  return window.L18N[key] || key
}

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app');

