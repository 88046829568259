
import axios from 'axios';
import { Component, Prop, Vue } from 'vue-property-decorator';

import BackBar from '@/components/BackBar.vue';
@Component({
    components: {
        BackBar,
    },
})
export default class Reg extends Vue {
    @Prop()
    private src!: string;

    @Prop()
    private checked!: string;

    codeSended = false;
    interval = 0;

    ruleForm = {
        email: '',
        code: '',
    };
    rules = {
        email: [
            {
                required: true,
                message: window.L('请输入正确的邮箱地址'),
                trigger: 'blur',
                pattern: /^([a-zA-Z0-9]+[_|_|\-|.]?)*[a-zA-Z0-9]+@([a-zA-Z0-9]+[_|_|.]?)*[a-zA-Z0-9]+\.[a-zA-Z]{2,6}$/,
            },
        ],
        code: [{ required: true, message: window.L('请输入'), trigger: 'blur' }],
    };

    targetInterval = 0;

    async fetchSysAvatarList() {
        const resp = await axios.get('/api/misc/get?tpl=avatar');
        const list = JSON.parse(resp.data.data.text);
        return list;
    }

    async mounted() {
        1;
    }
    checkEmailExist() {
        if (!this.ruleForm.email) {
            return;
        }
        axios
            .post('/reg/reg-email-check-exist', {
                email: this.ruleForm.email,
            })
            .then(data => {
                if (data.data.code !== 0) {
                    this.$message({
                        message: data.data.msg,
                        type: 'error',
                        showClose: true,
                        offset: 0,
                        duration: 1600,
                    });
                }
            });
    }
    sendCode() {
        const match = /^([a-zA-Z0-9]+[_|_|\-|.]?)*[a-zA-Z0-9]+@([a-zA-Z0-9]+[_|_|.]?)*[a-zA-Z0-9]+\.[a-zA-Z]{2,6}$/gi;
        if (!this.ruleForm.email || !match.test(this.ruleForm.email)) {
            this.$message({
                message: window.L('请输入正确的邮箱地址'),
                type: 'error',
                showClose: true,
                offset: 0,
                duration: 1600,
            });
            return;
        }

        this.$message({
            message: 'Please use the test account temporarily',
            type: 'success',
            showClose: true,
            offset: 0,
            duration: 1600,
        });

        // axios
        //     .post('/reg/reg-email-send-code', {
        //         email: this.ruleForm.email,
        //     })
        //     .then(data => {
        //         this.$message({
        //             message: data.data.msg,
        //             type: data.data.code === 0 ? 'success' : 'error',
        //             showClose: true,
        //             offset: 0,
        //             duration: 1600,
        //         });
        //         if (data.data.code === 0) {
        //             this.codeSended = true;
        //             this.interval = 60;
        //             this.targetInterval = setInterval(() => {
        //                 this.interval--;
        //                 if (this.interval <= 0) {
        //                     this.interval = 0;
        //                     clearInterval(this.targetInterval);
        //                 }
        //             }, 1000);
        //         }
        //     });
    }

    L(key: string) {
        return window.L(key);
    }
    submit() {
        (this.$refs as any).ruleForm.validate((result: any) => {
            if (result) {
                axios
                    .post('/reg/reg-email', {
                        email: this.ruleForm.email,
                        code: this.ruleForm.code,
                    })
                    .then(data => {
                        this.$message({
                            message: data.data.msg,
                            type: data.data.code === 0 ? 'success' : 'error',
                            showClose: true,
                            offset: 0,
                            duration: 1600,
                        });
                        if (data.data.code === 0) {
                            this.$router.replace('/modify');
                        }
                    });
            }
        });
    }
}
