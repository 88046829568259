
import axios from 'axios';
import { Component, Prop, Vue } from 'vue-property-decorator';
import Avatar from '@/components/Avatar.vue';
import BackBar from '@/components/BackBar.vue';
@Component({
    components: {
        Avatar,
        BackBar,
    },
})
export default class AvatarSelect extends Vue {
    @Prop()
    src!: string;

    @Prop()
    checked!: string;

    nickname = '';
    avatar = '';
    list = [];

    async fetchSysAvatarList() {
        const resp = await axios.get('/api/misc/get?tpl=avatar');
        const list = JSON.parse(resp.data.data.text);
        return list;
    }

    async mounted() {
        this.fetchSysAvatarList().then(data => {
            this.list = data;
        });

        const result = await axios.get('/api/follow/my-ext-info');
        console.log(`result`, result);
        this.nickname = result.data.data.nickname;
        this.avatar = result.data.data.avatar;
    }

    select(item: any) {
        console.log(item);
        this.avatar = item.url;
    }

    submit() {
        console.log(222, 'submit');
        axios
            .post('/api/misc/set-avatar', {
                avatar: this.avatar,
            })
            .then(() => {
                this.$message({
                    message: window.L(`更新头像成功`),
                    type: 'success',
                    showClose: true,
                    offset: 0,
                    duration: 1600,
                });
                setTimeout(() => {
                    this.$router.back();
                }, 300);
            });
    }
    L(key: string) {
        return window.L(key);
    }
}
