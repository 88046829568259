
import axios from 'axios';
import { Component, Prop, Vue } from 'vue-property-decorator';

import BackBar from '@/components/BackBar.vue';
@Component({
    components: {
        BackBar,
    },
})
export default class AvatarSelect extends Vue {
    @Prop()
    private src!: string;

    @Prop()
    private checked!: string;

    ruleForm = {
        email: '',
        pwd: '',
    };
    inpViewStatus = {
        pwd: false,
    };
    rules = {
        email: [{ required: true, message: window.L('请输入'), trigger: 'blur' }],
        pwd: [{ required: true, message: window.L('请输入'), trigger: 'blur' }],
    };

    async fetchSysAvatarList() {
        const resp = await axios.get('/api/misc/get?tpl=avatar');
        const list = JSON.parse(resp.data.data.text);
        return list;
    }
    L(key: string) {
        return window.L(key);
    }
    async mounted() {
        1;
    }
    forget() {
        this.$router.push('/modify');
    }
    goReg() {
        this.$router.push('/reg');
    }
    goNativeBack() {
        location.href = `mmid://fake-example.net/cmd#GotoMyScore`;
    }
    submit() {
        (this.$refs as any).ruleForm.validate((result: any) => {
            // monkey
            const userObj: any = {};
            if (this.$route.query.account) {
                userObj.username = `${this.$route.query.account}`.replaceAll(`"`, '');
            }

            if (result) {
                const formData = {
                    ...this.ruleForm,
                    ...userObj,
                };
                axios.post('/user-login', formData).then(data => {
                    this.$message({
                        message: data.data.msg,
                        type: data.data.code === 0 ? 'success' : 'error',
                        showClose: true,
                        offset: 0,
                        duration: 1600,
                    });
                    if (data.data.code === 0) {
                        const mail = encodeURIComponent(formData.email);

                        location.href = `mmid://fake-example.net/cmd?AccountMail=${mail}&AccMailPass=${formData.pwd}#StorageSave`;
                        setTimeout(() => {
                            this.$router.replace('/my');
                            // location.href = `mmid://fake-example.net/cmd#GotoAccountLogin`;
                        }, 200);
                    }
                });
            }
        });
    }

    beforeCreate() {
        // const currUrl = new URL(window.location.href);
        // let account = currUrl.searchParams.get("account");
        // if (account) {
        //   this.$router.replace(`/my?account=${account}`);
        // }
    }
}
