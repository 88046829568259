
import axios from 'axios';
import { Component, Prop, Vue } from 'vue-property-decorator';

import BackBar from '@/components/BackBar.vue';
@Component({
    components: {
        BackBar,
    },
})
export default class AvatarSelect extends Vue {
    @Prop()
    private src!: string;

    @Prop()
    private checked!: string;

    ruleForm = {
        email: '',
        oldPwd: '',
        pwd1: '',
        pwd2: '',
    };

    inpViewStatus = {
        oldPwd: false,
        pwd1: false,
        pwd2: false,
    };
    rules = {
        email: [{ required: true, message: window.L('请输入'), trigger: 'blur' }],
        oldPwd: [{ required: true, message: window.L('请输入'), trigger: 'blur' }],
        pwd1: [
            { required: true, message: window.L('请输入新密码'), trigger: 'blur' },
            {
                required: true,
                message: window.L('输入 6-20 位密码'),
                trigger: 'blur',
                type: 'string',
                min: 6,
                max: 20,
            },
        ],
        pwd2: [
            { required: true, message: window.L('请输入新密码'), trigger: 'blur' },
            {
                required: true,
                message: window.L('输入 6-20 位密码'),
                trigger: 'blur',
                type: 'string',
                min: 6,
                max: 20,
            },
        ],
    };

    async fetchSysAvatarList() {
        const resp = await axios.get('/api/misc/get?tpl=avatar');
        const list = JSON.parse(resp.data.data.text);
        return list;
    }
    L(key: string) {
        return window.L(key);
    }
    async mounted() {
        const result = await axios.post('/reg/reg-email-init-change-pwd');
        this.ruleForm.email = result.data.data.email;
        this.ruleForm.oldPwd = result.data.data.pwd;
    }
    forget() {
        1;
    }
    goReg() {
        1;
    }
    submit() {
        (this.$refs as any).ruleForm.validate((result: any) => {
            if (result) {
                if (this.ruleForm.pwd1 !== this.ruleForm.pwd2) {
                    this.$message.error(window.L('两次输入的密码不一致'));
                    return;
                }
                axios
                    .post('/reg/reg-email-modify-pwd', {
                        oldPwd: this.ruleForm.oldPwd,
                        newPwd: this.ruleForm.pwd1,
                    })
                    .then(data => {
                        this.$message({
                            message: data.data.msg,
                            type: data.data.code === 0 ? 'success' : 'error',
                            showClose: true,
                            offset: 0,
                            duration: 1600,
                        });
                        if (data.data.code === 0) {
                            this.$router.replace('/my');
                        }
                    });
            }
        });
    }
}
