
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class Avatar extends Vue {
    @Prop()
    title!: string;

    @Prop({
        default: false,
    })
    useBtn!: boolean;

    mounted() {
        console.log('m');
    }

    save() {
        this.$emit('save');
    }
    back() {
        console.log('back clicked');
        this.$emit('back');
    }

    L(key: string) {
        return window.L(key);
    }
}
