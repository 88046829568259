
import axios from 'axios';
import { Component, Prop, Vue } from 'vue-property-decorator';
import Avatar from '@/components/Avatar.vue';
import BackBar from '@/components/BackBar.vue';
import UserCard from '@/components/hall/userCard.vue';

@Component({
    components: {
        Avatar,
        BackBar,
        UserCard,
    },
})
export default class UserList extends Vue {
    activeName = 'release';
    promote = {
        id: 0,
        followed: false,
        url: '',
        avatar: '',
        name: '名字',
        desc: '一些问题',
    };

    userListData = [
        {
            id: 1,
            img: '',
            name: '名称',
            desc: 'desc',
            followed: false,
        },
    ];

    mapping(item: any) {
        return {
            id: item.id || Math.random(),
            img: item.avatar || `https://static.original-fun.com/music/avatar/7.png`,
            name: item.nickname || window.L('暂无'),
            desc: item.info || window.L('暂无'),
        };
    }

    async mounted() {
        const result = await this.fetchUserListData();
        result.forEach((element: any) => {
            element.followed = false;
        });
        this.userListData = result;
        console.log(` this.userListData`, this.userListData);

        const promote = await axios.get('/api/misc/get?tpl=promote');
        const promoteObj = JSON.parse(promote.data.data.text);
        this.promote = promoteObj;

        await this.fetchFollowData();
    }
    // 获取所有用户
    async fetchUserListData(isFollow = false) {
        const userListData = await axios.get('/api/follow/all-users?isFollower=' + isFollow);
        return userListData.data.data.map((item: any) => {
            return this.mapping(item);
        });
    }

    // 获取我关注的用户
    async fetchFollowerListData(isFollow = false) {
        const follower = await axios.get('/api/follow/my-follower?isFollower=' + isFollow);
        return follower.data.data.data.list.map((item: any) => {
            return this.mapping(item);
        });
    }

    //关注
    async goFollow(userId: any) {
        const result = await axios.post('/api/follow/addFollow', {
            target: userId,
        });
        return result;
    }

    //取消关注
    async unFollow(userId: any) {
        const result = await axios.post('/api/follow/remove', {
            target: userId,
        });
        return result;
    }
    // 更换 tab
    async handleClick(tab: any, event: any) {
        console.log(tab, event);

        if ('follow' === this.activeName) {
            const followers = await this.fetchFollowerListData();
            console.log(`fetchFollowerListData`, followers, this.activeName);
            this.userListData = followers;
        }

        if ('release' === this.activeName) {
            const users = await this.fetchUserListData();
            this.userListData = users;
        }
        await this.fetchFollowData();
    }

    //------- 顶部
    followBtnClick(promote: any, isFollow: boolean) {
        console.log(promote, isFollow);
        this.promote.followed = !this.promote.followed;
        this.follow(isFollow, promote.id);
    }

    //....
    follow(arg: boolean, id: number) {
        if (arg) {
            this.goFollow(id);
        } else {
            this.unFollow(id);
        }
    }

    goPage(item: any) {
        this.$router.push('/hallUserInfo?id=' + item.id);
    }

    // 查询哪些 id 是关注了的
    async fetchFollowData() {
        const ids = [...this.userListData, this.promote].map(item => {
            return item.id;
        });
        const resp: any = await axios.get('/api/follow/isFollow?ids=' + ids.join(','));

        const result = resp.data.data;

        this.userListData.forEach((item: any) => {
            item.followed = result[item.id + ''];
        });
        this.userListData = JSON.parse(JSON.stringify(this.userListData));
        this.promote.followed = result[this.promote.id + ''];
        console.log(`fetchFollowData`, result, this);
    }
}
