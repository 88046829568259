
import { Component, Prop, Vue } from "vue-property-decorator";
import axios from "axios";

@Component
export default class CreationCard extends Vue {
  @Prop({
    type: Number,
    default: 0,
  })
  private id!: number;

  @Prop({
    type: String,
    default: "https://static.original-fun.com/music/avatar/2.png",
  })
  private img!: string;

  @Prop({
    type: Boolean,
    default: false,
  })
  private followed!: boolean;

  @Prop({
    type: String,
    default: "name",
  })
  private name!: string;

  @Prop({
    type: String,
    default: "desc",
  })
  private desc!: string;

  follow(arg: boolean) {
    this.followed = arg;
    this.$emit("follow", arg, this.id);
  }
}
