
import axios from 'axios';
import Avatar from '@/components/Avatar.vue';
import BackBar from '@/components/BackBar.vue';
import { Component, Prop, Vue } from 'vue-property-decorator';
import CreationCard from '@/components/hall/creationCard.vue';
import { Message } from 'element-ui';

@Component({
    components: {
        Avatar,
        BackBar,
    },
})
export default class StaticShareInternal extends Vue {
    bg = '';
    audioSrc = '';
    title = '';
    desc = '';
    thumb = false;

    async mounted() {
        const info = await axios.get('/get-share-creation?id=' + this.$route.query.id);
        this.bg = info.data.data.imgUrl;
        this.audioSrc = info.data.data.file;
        this.title = info.data.data.title;
        this.desc = info.data.data.desc;
        this.fetchIsThumb();
    }

    thumbClick(isThumb: boolean) {
        console.log(isThumb, `isThumb`);
        const id = this.$route.query.id;
        if (isThumb) {
            this.goThumb(id);
        } else {
            this.unThumb(id);
        }
        this.thumb = isThumb;
    }

    //关注
    async goThumb(creationId: any) {
        const result = await axios.post('/api/thumb/add', {
            id: creationId,
        });
        return result;
    }

    //取消关注
    async unThumb(creationId: any) {
        const result = await axios.post('/api/thumb/remove', {
            id: creationId,
        });

        return result;
    }

    async fetchIsThumb() {
        const id: string = this.$route.query.id as string;
        const result = await axios.get('/api/thumb/isThumb?ids=' + id);
        this.thumb = !!result.data.data[id];
        console.log(`fetchIsThumb`, result);
    }
    goShare() {
        navigator.clipboard.writeText('https://music.original-fun.com/shareStatic/?id=' + this.$route.query.id);
        Message.success(window.L('分享链接已复制到剪贴板'));
    }
    goBack() {
        this.$router.back();
    }

    async report(id: any) {
        const result = await axios.post('/report', {
            id,
        });
        if (result?.data?.code === 0) {
            this.$message.success(result?.data?.msg);
        } else {
            this.$message.error(result?.data?.msg);
        }
    }
}
