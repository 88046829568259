
import axios from 'axios';
import { Component, Prop, Vue } from 'vue-property-decorator';
import CreationCard from '@/components/hall/creationCard.vue';
import BackBar from '@/components/BackBar.vue';

@Component({
    components: {
        CreationCard,
        BackBar,
    },
})
export default class UserInfo extends Vue {
    activeName = 'first';
    userInfo = {
        nickname: 'nickname',
        info: 'desc',
        thumbCount: 1111,
        isFollow: false,
    };

    creationList = [
        {
            id: 1,
            img: '',
            name: 'name',
            during: 0,
            desc: 'desc',
        },
        {
            id: 2,
            img: '',
            name: 'name',
            during: 0,
            desc: 'desc',
        },
    ];

    async mounted() {
        await this.fetchUserInfo();
        await this.fetchCreationList();
        await this.isPromoteFollowed();
        console.log('ccc', this);
    }
    // 获取基本的信息
    async fetchUserInfo() {
        const info = await axios.get('/api/follow/target-info?id=' + this.$route.query.id);
        console.log(`info`, info);
        this.userInfo = { ...this.userInfo, ...info.data.data };
    }

    async isPromoteFollowed() {
        const id = this.$route.query.id as string;
        const follow = await axios.get('/api/follow/isFollow?ids=' + id);
        console.log(`followfollow`, follow);
        this.userInfo.isFollow = follow.data.data[id];
    }

    async fetchCreationList() {
        const list = await axios.get('/api/follow/getUserCreation?id=' + this.$route.query.id);
        console.log(`fetchCreationList`, list.data.data);
        this.creationList = list.data.data;
    }

    // async mounted() {}followClick
    handleTabClick(tab: any, event: any) {
        console.log(tab, event);
    }

    //关注
    async goFollow(userId: any) {
        const result = await axios.post('/api/follow/addFollow', {
            target: userId,
        });

        return result;
    }

    //取消关注
    async unFollow(userId: any) {
        const result = await axios.post('/api/follow/remove', {
            target: userId,
        });

        return result;
    }

    followClick(isFollow: boolean) {
        console.log(isFollow, `isFollow`);
        const id = this.$route.query.id;
        if (isFollow) {
            this.goFollow(id);
        } else {
            this.unFollow(id);
        }
        this.userInfo.isFollow = isFollow;
        console.log(2222, this);
    }

    creationClick(item: any) {
        console.log('creationClick', item);
        this.$router.push(`/creation?id=${item.id}`);
    }
    goBack() {
        this.$router.back();
    }
}
