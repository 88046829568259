
import axios from "axios";
import Avatar from "@/components/Avatar.vue";
import BackBar from "@/components/BackBar.vue";
import { Component, Prop, Vue } from "vue-property-decorator";
import CreationCard from "@/components/hall/creationCard.vue";
import { Message } from "element-ui";

@Component({
  components: {
    Avatar,
    BackBar,
  },
})
export default class PaletteConfirm extends Vue {
  bg = "";
  audioSrc = "";
  title = "";
  desc = "";
  thumb = false;
  isLogin = false;

  get queryId() {
    let id: any = this.$route.query.id || "";
    return id.replaceAll(`"`, "").replaceAll(`%22`, "");
  }

  async fetchIsLogin() {
    const loginData = await axios.get("/is-login");
    console.log(`fetchIsLogin`, loginData);
    this.isLogin = !!loginData.data.data;
    return loginData.data.data;
  }

  async mounted() {
    this.fetchIsLogin();
    const info = await axios.get("/get-share-creation?id=" + this.queryId);
    this.bg = info.data.data.imgUrl;
    this.audioSrc = info.data.data.file;
    this.title = info.data.data.title;
    this.desc = info.data.data.desc;
    console.log(22222, info.data.data);
    this.fetchIsThumb();
  }

  L(key: string) {
    return window.L(key);
  }

  thumbClick(isThumb: boolean) {
    console.log(isThumb, `isThumb`);
    const id = this.queryId;
    if (isThumb) {
      this.goThumb(id);
    } else {
      this.unThumb(id);
    }
    this.thumb = isThumb;
  }

  //关注
  async goThumb(creationId: any) {
    const result = await axios.post("/api/thumb/add", {
      id: creationId,
    });
    return result;
  }

  //取消关注
  async unThumb(creationId: any) {
    const result = await axios.post("/api/thumb/remove", {
      id: creationId,
    });

    return result;
  }

  async fetchIsThumb() {
    const id: string = this.queryId as string;
    const result = await axios.get("/api/thumb/isThumb?ids=" + id);
    this.thumb = !!result.data.data[id];
    console.log(`fetchIsThumb`, result);
  }
  goShare() {
    navigator.clipboard.writeText(
      "https://music.original-fun.com/shareStatic/?id=" + this.queryId
    );
    Message.success(window.L(`分享链接已复制到剪贴板`));
  }
  goBack() {
    this.$router.back();
  }
  save() {
    console.log(2222);
  }

  share2(tp: string) {
    if (tp === "wx") {
      window.alert("to be done");
    }
    if (tp === "link") {
      window.navigator.clipboard.writeText(
        `https://music.original-fun.com/shareStatic/?id=${this.queryId}`
      );
      this.$message.success("link send to clipboard");
    }
  }

  async publish(tp: any) {
    console.log(`publish`, tp);
    if (tp === "hall") {
      await axios.post("/api/follow/publishToHall", {
        id: this.queryId,
        isPublish: true,
      });
      this.$message.success(this.L(`发布到音乐厅`));
    } else {
      this.$message.success(this.L(`仅保存到我的创作`));
    }
  }

  doLogin() {
    this.$router.push("/login");
  }
}
