
import { Component, Vue } from 'vue-property-decorator';
import Avatar from '@/components/Avatar.vue';
import axios from 'axios';
import dayjs from 'dayjs';
import AvatarSelecter from '@/components/AvatarSelecter.vue';
import BackBar from '@/components/BackBar.vue';

@Component({
    components: {
        Avatar,
        BackBar,
    },
})
export default class HomeView extends Vue {
    nickname = '';
    days = '';
    creationCount = '';
    info = '';
    followCount = '';
    beingFollowCount = '';
    thumbCount = '';
    createTime = '';
    avatar = '';

    L(key: string) {
        return window.L(key);
    }

    async mounted() {
        const result = await axios.get('/api/follow/my-ext-info');

        this.nickname = result.data.data.nickname;
        this.days = result.data.data.days;
        this.creationCount = result.data.data.creationCount;
        this.info = result.data.data.info;
        this.followCount = result.data.data.followCount;
        this.beingFollowCount = result.data.data.beingFollowCount;
        this.thumbCount = result.data.data.thumbCount;
        this.createTime = dayjs(result.data.data.createTime).format('YYYY-MM-DD');
        this.avatar = result.data.data.avatar;
    }

    avatarClick() {
        this.$router.push('/avatar-select');
    }
    goBack() {
        console.log('go ', `mmid://fake-example.net/cmd#GotoMyScore`);
        location.href = `mmid://fake-example.net/cmd#GotoMyScore`;
    }

    infoChange(val: string) {
        axios.post('/api/misc/set-info', {
            info: val,
        });
    }

    async delMe() {
        await this.$confirm(this.L('您确定删除您的账号么?'), '', { customClass: 'confirm-customClass' });
        await this.$confirm(this.L('再次确认,您确定删除您的账号么? 操作无法撤回'), '', { customClass: 'confirm-customClass' });

        await axios.post('/api/follow/delMe', {});

        await this.$confirm(this.L('您的账号已删除'));

        setTimeout(() => {
            this.$router.replace('/reg');
        }, 500);

        location.href = `mmid://fake-example.net/cmd?AccountMail=""&AccMailPass=""#StorageSave`;
    }
}
