
import axios from 'axios';
import { Component, Prop, Vue } from 'vue-property-decorator';
import Avatar from '@/components/Avatar.vue';
import BackBar from '@/components/BackBar.vue';
@Component({
    components: {
        Avatar,
        BackBar,
    },
})
export default class EditNickName extends Vue {
    nickname = '';
    async mounted() {
        const result = await axios.get('/api/follow/my-ext-info');
        this.nickname = result.data.data.nickname;
    }

    L(key: string) {
        return window.L(key);
    }
    save() {
        axios
            .post('/api/misc/set-nickname', {
                nickname: this.nickname,
            })
            .then(() => {
                this.$message({
                    message: window.L('更新昵称成功'),
                    type: 'success',
                    showClose: true,
                    offset: 0,
                    duration: 1600,
                });
                setTimeout(() => {
                    this.$router.back();
                }, 300);
            });
    }
}
