
import { Component, Prop, Vue } from "vue-property-decorator";
import axios from "axios";
@Component
export default class Avatar extends Vue {
  @Prop({
    type: String,
    default: "https://static.original-fun.com/music/avatar/user.png",
  })
  private src!: string;

  @Prop()
  private checked!: boolean;

  mounted() {
    console.log("m");
  }
}
