
import axios from 'axios';
import { Component, Prop, Vue } from 'vue-property-decorator';
import CreationCard from '@/components/hall/creationCard.vue';
import BackBar from '@/components/BackBar.vue';

@Component({
    components: {
        CreationCard,
        BackBar,
    },
})
export default class MyCreationList extends Vue {
    creationList = [
        {
            id: 1,
            img: '',
            name: 'name',
            during: 0,
            desc: 'desc',
        },
        {
            id: 2,
            img: '',
            name: 'name',
            during: 0,
            desc: 'desc',
        },
    ];

    async mounted() {
        await this.fetchCreationList();

        console.log('ccc', this);
    }
    // 获取基本的信息

    async fetchCreationList() {
        const list = await axios.get('/api/follow/getMyCreation');
        console.log(`fetchCreationList`, list.data.data);
        this.creationList = list.data.data;
    }

    creationClick(item: any) {
        this.$router.push(`/palettePreview?id=${item.id}`);
    }
    goBack() {
        this.$router.back();
    }
}
