import { render, staticRenderFns } from "./AvatarSelect.vue?vue&type=template&id=44b15a5d&scoped=true&"
import script from "./AvatarSelect.vue?vue&type=script&lang=ts&"
export * from "./AvatarSelect.vue?vue&type=script&lang=ts&"
import style0 from "./AvatarSelect.vue?vue&type=style&index=0&id=44b15a5d&prod&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "44b15a5d",
  null
  
)

export default component.exports