
import { Component, Prop, Vue } from "vue-property-decorator";
import axios from "axios";

@Component
export default class CreationCard extends Vue {
  @Prop({
    type: String,
    default: "https://static.original-fun.com/music/avatar/2.png",
  })
  private img!: string;

  @Prop({
    type: Boolean,
    default: false,
  })
  private follow!: boolean;

  @Prop({
    type: String,
    default: "",
  })
  private name!: string;

  @Prop({
    type: String,
    default: "",
  })
  private desc!: string;

  @Prop({
    type: [String, Number],
    default: "",
  })
  private duration!: number | string;

  mounted() {
    console.log("userCard");
  }
}
