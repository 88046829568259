import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import My from '../views/My.vue'
import AvatarSelect from '../views/AvatarSelect.vue'
import EditNickName from '../views/EditNickName.vue'
import Login from '../views/login/login.vue'
import Reg from '../views/login/reg.vue'
import Modify from '../views/login/modify.vue'
import HallUserInfo from '../views/hall/userInfo.vue'
import HallUserList from '../views/hall/userList.vue'
import StaticShareInternal from '../views/hall/share.vue'

import paletteConfirm from '../views/palette/confirm.vue'
import paletteMyCreation from '../views/palette/myCreation.vue'
import palettePreview from '../views/palette/preview.vue'



Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/login',
    name: 'login',
    component: Login
  },

  {
    path: '/reg',
    name: 'reg',
    component: Reg
  },

  {
    path: '/modify',
    name: 'modify',
    component: Modify
  },

  {
    path: '/my',
    name: 'my',
    component: My
  },
  {
    path: '/edit-nick-name',
    name: 'edit-nick-name',
    component: EditNickName
  },
  {
    path: '/avatar-select',
    name: 'avatar-select',
    component: AvatarSelect
  },

  {
    path: '/hallUserInfo',
    name: 'hall-user-info',
    component: HallUserInfo
  },

  {
    path: '/hall',
    name: 'hall-user-list',
    component: HallUserList
  },

  {
    path: '/creation',
    name: 'hall-creation',
    component: StaticShareInternal
  },

  {
    path: '/paletteConfirm',
    name: 'palette-Confirm',
    component: paletteConfirm
  },
  {
    path: '/paletteMyCreation',
    name: 'palette-MyCreation',
    component: paletteMyCreation
  },
  {
    path: '/palettePreview',
    name: 'palette-Preview',
    component: palettePreview
  },


  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
